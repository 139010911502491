import Vue from 'vue'

import * as Sentry from '@sentry/vue'

if (SENTRY_PUBLIC_DSN) {
  Sentry.init({
    Vue,
    dsn: SENTRY_PUBLIC_DSN,
    debug: NODE_ENV !== 'production',
    release: REVISION,
    integrations: [],
  })

  const scope = Sentry.getCurrentScope()
  scope.setTag('logger', 'javascript')
  scope.setUser({ id: window.ENV.CustomerUuid })
}
